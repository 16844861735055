@import '../../theme/styles/_var';

.statistic {
  &__title {
    font-size:$subTitleFontSize;
    margin: 0;
    @media (min-width: $mobMin) and (max-width: $tabMax) {
      font-size:$titleFontSizeTab;
    }
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      text-align: center;
    }
    }

  &__background {
    background-image: url("../../svg/backgrounds/about.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top, bottom;
    padding-bottom: 40px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top:$marginFromTitle;
      @media (min-width: $tabMin) and (max-width: $tabMax) {
      padding-top: 20px;
      vertical-align: center;
        align-items: center;
      }
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      padding-top: 20px;
      flex-direction: column;
    }
    }

  &__stat {
    width: 50%;
    display: grid;
    gap: 40px 80px ;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 50%);
    grid-auto-flow: column;
    @media (min-width: $deskMin) and (max-width: $deskMax) {
      gap: 40px;
    }
    @media (min-width: $tabMin) and (max-width: $tabMax) {
      width: 50%;
      gap: 20px;
    }
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      width: calc(100% - 20px);
      gap: 20px;
    }
    }

  &__card {
    display: flex;
    flex-direction: column;
  }

  &__to_right_mob {
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      text-align: right;
    }
  }

  &__card_title {
    font-size: $textFontSize;
    @media (min-width: $deskMin) and (max-width: $deskMax) {
      font-size: 20px;
    }
    @media (min-width: $mobMin) and (max-width: $tabMax) {
      font-size:12px;
    }
    }
  &__card_data {
    font-family: 'Koulen', sans-serif;
    font-size: 48px;
    color:$secondaryColor;
    margin: 0;
    letter-spacing: 4px;
    line-height: 1.2;
    @media (min-width: $mobMin) and (max-width: $tabMax) {
      font-size:$titleFontSizeTab;
    }
  }

  &__svg_wrapper {
    display: flex;
    @media (min-width: $tabMin) and (max-width: $tabMax) {
      justify-content: end;
    }
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      justify-content: center;
    }
    }

  &__svg {
    height: initial;
    @media (min-width: $tabMin) and (max-width: $tabMax) {
      width: 90%;
    }
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      width: 80%;
      margin-top: 20px;
    }
    }
}