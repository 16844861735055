@import "src/theme/styles/_var";

.about {
  padding: 50px 150px;
  display: flex;
  justify-content: space-between;
  background-image: url("../../svg/backgrounds/aboutTop.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top, bottom;

  @media (min-width: $deskXMin) and (max-width: $deskXMax) {
    padding: 50px ;
  }

  @media (min-width: $deskMin) and (max-width: $deskMax) {
    padding: 50px 0 50px 50px;
  }
  @media (min-width: $tabMin) and (max-width: $tabMax) {
    padding: 50px;
  }
  @media (min-width: $mobMin) and (max-width: $mobMax) {
    padding: 6px 20px 20px 20px;
    flex-direction: column;
  }

  &__svg_wrapper {
    display: flex;
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      margin-top: 20px;
      justify-content: center;
    }
    }

  &__svg {
    @media (min-width: $tabMin) and (max-width: $tabMax) {
      width: 80%;
      height: min-content;
    }
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      width: 50%;
      height: min-content;
    }
    }

  &__text_wrapper {
    width: 50%;
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      width: 100%;
    }
    }

  &__title {
    font-size: $subTitleFontSize;
    margin: 0;
    @media (min-width: $tabMin) and (max-width: $tabMax) {
      font-size: 24px;
    }
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      text-align: center;
      font-size: 24px;
      position: relative;
      top:-20px;
    }
    }

  &__description {
    margin-top: $marginFromTitle;
    display: block;
    font-size: $textFontSize;
    @media (min-width: $tabMin) and (max-width: $tabMax) {
      font-size:$textFontSizeTab;
      margin-top: 20px;
    }
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      margin-top: 20px;
      font-size:$textFontSizeMob;
      text-align: center;
    }
    }
}