// colors
$mainColor: #2F4858;
//$secondaryColor: #00e05a;
$secondaryColor: #00f863;
//$secondaryColor: #04a946;

$darkThemeMain: #2F4858;
$darkThemeSecondary:  #19FF00;

// @media width

$mobMin: 320px;
$mobMax: 480px;
$tabMin: 481px;
$tabMax: 992px;
$deskMin: 993px;
$deskMax: 1200px;
$deskXMin: 1201px;
$deskXMax: 1450px;

$titleFontSize: 64px;
$titleFontSizeDesk: 48px;
$titleFontSizeTab: 24px;

$subTitleFontSize: 48px;

$textFontSize: 24px;
$textFontSizeTab: 16px;
$textFontSizeMob: 14px;
//margins
$marginFromTitle: 50px;

//
//$fontSizeTitleH1: 28px;
//$fontSizeTitleH1_tab: 26px;
//$fontSizeTitleH1_mobile: 24px;
//
//$fontSizeTitleH2: 24px;
//$fontSizeTitleH2_tab: 22px;
//$fontSizeTitleH2_mobile: 18px;
//
//$fontSizeTitleH3: 22px;
//$fontSizeTitleH3_tab: 18px;
//$fontSizeTitleH3_mobile: 16px;
//
//$fontSizeButton: 18px;
//$fontSizeButton_tab: 16px;
//$fontSizeButton_mobile: 15px;
//
//$fontSizeText: 16px;
//$fontSizeText_tab: 14px;
//$fontSizeText_mobile: 13px;