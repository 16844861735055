@import "src/theme/styles/_var";

.form {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 50px;
  color: black;

  @media (min-width: $tabMin) and (max-width: $tabMax) {
    margin: 20px auto;
    justify-content: center;
    width: 50%;
  }

  @media (min-width: $mobMin) and (max-width: $mobMax) {
    margin-top: 20px;
  }

    &__label {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__label_text {
    font-weight: bold;
    display: flex;
    gap: 10px;
  }

  &__input {
    border: 1.5px solid black;
    padding: 5px;
    outline: none;
    background-color: inherit;
  }

  &__submit_button {
    border: 1.5px solid $secondaryColor;
    background-color:$secondaryColor;
    color: black;
    font-weight: bold;
    font-size: 20px;
    transition: 0.3s ease all;
    font-family: 'Koulen', cursive;
    padding: 10px;
    @media (min-width: $tabMin) and (max-width: $tabMax) {
    }
    }

  &__disable_submit {
    background-color: inherit;
    font-family: 'Source Sans Pro', sans-serif;
  }

  &__submit_button:hover{
    background-color: inherit;
  }

  &__error {
    color: red;
  }

  &__checkbox_wrapper {
    display: flex;
    vertical-align: center;
    align-items: center;
    gap: 10px;
  }

  &__checkbox_text {
    display: flex;
    flex-direction: column;
  }

  &__link {
    text-decoration: none;
    color: dodgerblue;
  }
}