@import "src/theme/styles/_var";

.section {

  &__title{
    font-size: 64px;
    margin: 0;
    @media (min-width: $mobMin) and (max-width: $tabMax) {
      text-align: center;
      font-size:$titleFontSizeTab;
    }
    }

  &__wrapper {
   display: flex;
    margin-top: 50px;
    @media (min-width: $tabMin) and (max-width: $tabMax) {
      flex-direction: column;
      margin-top: 0;
    }
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      flex-direction: column;
      margin-top: 0;
    }
    }

  &__form_container {
    width: 50%;
    padding: 60px;
    @media (min-width: $mobMin) and (max-width: $tabMax) {
      width: calc(100% - 40px);
      padding: 6px 20px 20px 20px;
      justify-content: center;
    }
    }


  &__contacts_container {
    width: 50%;
    background-color: black;
    display: flex;
    flex-direction: column;
    color: $secondaryColor;
    padding: 60px;
    gap: 80px;
    @media (min-width: $mobMin) and (max-width: $tabMax) {
      width: 100%;
      display: flex;
      justify-content: center;
      vertical-align: center;
      align-items: center;
      padding: 0;
      margin-top: 50px;
      gap: 50px;
    }
  }

  &__contacts_title {
    font-size: $textFontSize;
    margin: 0;
    @media (min-width: $mobMin) and (max-width: $tabMax) {
      text-align: center;
      margin-top: 50px;
      font-size:$titleFontSizeTab;
    }
  }

  &__contact_info {
    width: 70%;
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      width:90%;
    }

    }

  &__contact_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      flex-direction: column;
    }
    }

  &__contact_cell {
    width: 50%;
    }
}
